<template>
  <div style="height: 100vh" class="d-flex flex-column">
    <div class="container flex-grow-1">
      <div class="wrapper h-100 d-flex flex-column px-2">
        <Header title="" backto="/user" />
        <Menu class="mb-5" :menu="menu" />
        <div class="mb-5 pb-3 header"><h3>我的方案</h3></div>
        <EmptyState v-show="profile.license" notes="沒有可用的方案" icon="key" />
        <div v-for="(item, key) in profile.license" :key="key">
          <div class="card-item">
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="card-item-num">{{ item.orderId ? '點數會員' : item.name }}</div>
              <div class="d-flex card-item-desc">
                <span>{{
                  format(parseISO(item.startingTime), 'yyyy/MM/dd') +
                  '-' +
                  format(parseISO(item.endingTime), 'yyyy/MM/dd')
                }}</span>
                <span style="color: #76cc79" v-if="item.actived"> 使用中 </span>
                <span v-else>已停用</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="(profile.licenses || []).length === 0" @click="$router.push('/plan/add')">
      <Button name="新增試用方案"></Button>
    </div>
    <PlanModal v-if="planModalShow" :onClose="closePlanModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseISO, format, isAfter, isBefore, startOfToday } from 'date-fns';
import { auth } from '@/mixins';
import Menu from '@/components/Menu.vue';
import Header from '@/components/Header.vue';
import Button from '@/elements/Button.vue';
import PlanModal from '@/components/PlanModal.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
  mixins: [auth],
  computed: {
    ...mapState({
      profile: (state) => state.auth.profile,
    }),
  },
  data() {
    return {
      planModalShow: false,
      menu: [
        {
          name: 'PAMO 會員',
          id: 'plan',
          highlighted: true,
          clickEvent: true,
          notes: '方案說明',
          onClick: () => {
            this.planModalShow = true;
          },
        },
      ],
    };
  },
  components: {
    Header,
    Button,
    EmptyState,
    PlanModal,
    Menu,
  },
  methods: {
    parseISO,
    format,
    isActived(startingTime, endingTime) {
      const nowTime = startOfToday();
      console.log(
        isBefore(nowTime, parseISO(endingTime)),
        isAfter(nowTime, parseISO(startingTime))
      );
      return isBefore(nowTime, parseISO(endingTime)) && isAfter(nowTime, parseISO(startingTime));
    },
    closePlanModal() {
      this.planModalShow = false;
    },
  },
};
</script>

<style>
.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
}

.header {
  border-bottom: 1px solid grey;
}

.card-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  margin-bottom: 35px;
}

.card-item-num {
  font: normal normal normal 20px/24px Helvetica Neue;
  color: #ffffff;
}

.card-item-desc {
  font: normal normal bold 15px/18px Helvetica Neue;
  color: #6e6e6e;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
}

.card-item-delete {
  font: normal normal bold 15px/18px Helvetica Neue;
  color: #ff0045;
  cursor: pointer;
}
</style>
